import { memo } from "react";
import {
  Slide,
  toast,
  ToastContainer,
  ToastContainerProps,
} from "react-toastify";
import ToastCloseButton from "./ToastCloseButton";
import ToastIcon from "./ToastIcon";

/** Styling under {@link ToastifyStyles}. */
const JGToastContainer = memo((props: ToastContainerProps) => {
  return (
    <ToastContainer
      theme="colored"
      transition={Slide}
      position={toast.POSITION.TOP_CENTER}
      closeButton={ToastCloseButton}
      autoClose={20000}
      icon={ToastIcon}
      hideProgressBar
      {...props}
    />
  );
});

export default JGToastContainer;
