import React, { useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ExpiringSubscription } from "Api/SubscriptionApi";
import { getPlanName } from "Utils/Components/Plan";
import { Link } from "react-router-dom";
import { buildDonatePath, buildLegacyUpgradeDonatePath } from "Utils/Checkout";
import PlanRenewButton from "Pages/Components/PlanRenewButton";
import { useCookies } from "react-cookie";

export type PlanExpirationDialogProps = {
  subscription: ExpiringSubscription;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: (renewed: boolean) => void;
};

const PlanExpirationDialogContent = (props: PlanExpirationDialogProps) => {
  const { subscription, onClose } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [error, setError] = useState<any>(null);

  // Checking for renewal coupon in URL and setting a cookie
  let renewalCoupon: string = "";
  const [cookies, setCookie] = useCookies(["renewalCoupon"]);
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const renewalCouponParam = params.get("rc");
  const renewalCouponCookie = cookies.renewalCoupon;

  if (renewalCouponParam && !renewalCouponCookie) {
    renewalCoupon = renewalCouponParam;
    setCookie("renewalCoupon", renewalCouponParam, {
      maxAge: 60 * 60 * 24 * 1,
    }); // 1 day
  } else if (renewalCouponCookie) {
    renewalCoupon = renewalCouponCookie;
  }

  return (
    <>
      <DialogTitle>
        Your {getPlanName(subscription.planCode)} plan is expiring{" "}
        <strong>{subscription.termEnd.relativeFormattedDate}</strong>.
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={() => props.onClose(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        Renew now to keep your perks.{" "}
        {renewalCoupon ? "We've added a discount to your membership" : ""}
      </DialogContent>

      <DialogActions>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ justifyContent: "center", width: "100%" }}
          >
            {!isMobile && (
              <Button autoFocus color="neutral" onClick={() => onClose(false)}>
                Cancel
              </Button>
            )}

            <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
              <PlanRenewButton
                size={"medium"}
                color="primary"
                onSuccess={() => onClose(true)}
                onError={(err) => setError(err)}
                renewalCoupon={renewalCoupon}
                disableErrorToast
                subscription={{
                  id: subscription.id,
                  amount: subscription.amount,
                  donationType: subscription.donationType,
                  planCode: subscription.planCode,
                  originalPlanCode: subscription.originalPlanCode,
                  termEnd: subscription.termEnd,
                  autoRenew: false,
                }}
              >
                Renew My Plan Once
              </PlanRenewButton>
              <PlanRenewButton
                size={"medium"}
                color="primary"
                onSuccess={() => onClose(true)}
                onError={(err) => setError(err)}
                renewalCoupon={renewalCoupon}
                disableErrorToast
                subscription={{
                  id: subscription.id,
                  amount: subscription.amount,
                  donationType: subscription.donationType,
                  planCode: subscription.planCode,
                  originalPlanCode: subscription.originalPlanCode,
                  termEnd: subscription.termEnd,
                  autoRenew: true,
                }}
              >
                Turn on Auto-Renewal
              </PlanRenewButton>

              {subscription.legacyUpgradeOffer
                ? (() => {
                    const path = buildLegacyUpgradeDonatePath(subscription);
                    return (
                      <Button
                        component={Link}
                        to={path}
                        target="_blank"
                        onClick={() => onClose(false)}
                      >
                        Get 25% off Premium Plus!
                      </Button>
                    );
                  })()
                : (() => {
                    const goToPremium =
                      subscription.planCode === "unlimited-listening";
                    return (
                      goToPremium && (
                        <Button
                          component={Link}
                          variant="outlined"
                          to={goToPremium ? "/premium" : buildDonatePath()}
                          onClick={() => onClose(false)}
                        >
                          {"See Donation Options"}
                        </Button>
                      )
                    );
                  })()}
            </Stack>
          </Stack>

          {error && (
            <Alert severity="error">
              There was an issue renewing your plan. Please try again later.
            </Alert>
          )}
        </Stack>
      </DialogActions>
    </>
  );
};

const PlanExpirationDialog = (props: PlanExpirationDialogProps) => {
  /**
   * All state should be in {@link PlanExpirationDialogContent} so that it resets when the dialog is unmounted.
   */
  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      onClose={() => props.onClose(false)}
      PaperProps={{
        sx: { width: "100%" },
      }}
    >
      <PlanExpirationDialogContent {...props} />
    </Dialog>
  );
};

export default PlanExpirationDialog;
