import { useState } from "react";
import { ButtonProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useAsyncFn } from "react-use";
import { useAuth0 } from "react-auth0-spa";
import SubscriptionApi, { ExpiringSubscription } from "Api/SubscriptionApi";
// import ConfirmationDialog from "Pages/Components/ConfirmationDialog";

type PlanRenewButtonProps = Omit<ButtonProps, "onClick"> & {
  subscription: ExpiringSubscription;
  onClick?: () => void;
  onError?: (error: any) => void;
  onSuccess?: () => void;
  /**
   * Disable the renewal button if it attempts to route the user to the
   * checkout, like with standard plans.
   */
  disableCheckout?: boolean;
  // Disable a toast from displaying on error.
  disableErrorToast?: boolean;
  // Disable a toast from displaying on success.
  disableSuccessToast?: boolean;

  renewalCoupon?: string;
};

const PlanRenewButton = (props: PlanRenewButtonProps) => {
  const {
    subscription,
    onClick,
    onSuccess,
    onError,
    disableErrorToast = false,
    disableSuccessToast = false,
    renewalCoupon,
    children,
    ...rest
  } = props;
  const { refreshUserSilently } = useAuth0();
  // const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [{ loading }, renew] = useAsyncFn(
    async (isAutoRenew: boolean | undefined) => {
      if (!subscription.id) return;

      try {
        if (isAutoRenew) {
          await SubscriptionApi.reactivate(
            subscription.id,
            undefined,
            renewalCoupon
          );
        } else {
          await SubscriptionApi.reactivate(subscription.id, 1, renewalCoupon);
        }
        setDisabled(true);
        if (onSuccess) onSuccess();
        if (!disableSuccessToast) {
          toast.success(
            <>
              All set! Your plan will renew on{" "}
              <strong>{subscription.termEnd.formattedDate}</strong>
            </>
          );
        }

        refreshUserSilently();
      } catch (err) {
        console.error(err);

        if (onError) onError(err);
        if (!disableErrorToast) {
          toast.error(
            "There was an issue renewing your plan. If this continues, please contact support."
          );
        }
      }
    },
    [subscription.id]
  );

  // const handleConfirmDialogClose = (confirm: boolean) => {
  //   if (confirm) {
  //     renew(subscription.autoRenew);
  //     if (onClick) onClick();
  //   }

  //   setConfirmDialogOpen(false);
  // };

  if (
    subscription.originalPlanCode !== "premium-plus" &&
    subscription.originalPlanCode !== "premium-plus-gift" &&
    subscription.originalPlanCode !== "premium-plus-monthly"
  ) {
    return null;
  }

  return (
    <>
      <LoadingButton
        loading={loading}
        disabled={isDisabled}
        onClick={() => renew(subscription.autoRenew)}
        {...rest}
      >
        {children || "Renew My Plan"}
      </LoadingButton>

      {/* <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        confirmText="Renew"
      >
        Are you sure you want to renew this plan?
      </ConfirmationDialog> */}
    </>
  );
};

export default PlanRenewButton;
